import { triggerEvent } from '../../utils/dom'
import { createLoading } from '../loading'

var timer = null

export function submitForm(node, ajax = false) {
    if (!node) {
        return
    }

    const form = node.closest('form')

    if (ajax || form.classList.contains('ajax')) {
        let loading = null
        let finished = false

        $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: $(form).serialize(),
            beforeSend() {
                setTimeout(() => {
                    if (finished) {
                        return
                    }
                    loading = createLoading()
                    form.appendChild(loading)
                }, 500);
            },
            complete() {
                finished = true
                if (loading && loading.parentNode === form) {
                    form.removeChild(loading)
                }
            }
        });
    } else {
        form.submit()
    }
}

document.addEventListener('nette:autosubmit', event => {
    if (event.defaultPrevented) {
        return
    }

    submitForm(event.target)
})

document.addEventListener('change', event => {
    const form = event.target.closest('form')
    let shouldAutosubmit = false

    if (form?.dataset?.autosubmit != null || (event.target.dataset?.autosubmit != null && event.target.dataset?.autosubmit !== "false")) {
        shouldAutosubmit = true
    }

    if (event.target.closest('[data-autosubmit="false"]')) {
        shouldAutosubmit = false
    }

    if (shouldAutosubmit) {
        triggerEvent(form, 'nette:autosubmit')
    }
})

document.addEventListener('keyup', event => {
    const form = event.target.closest('form') || event.target.form
    let shouldAutosubmit = false

    if (form?.dataset?.autosubmit != null || (event.target.dataset?.autosubmit != null && event.target.dataset?.autosubmit !== "false")) {
        shouldAutosubmit = true
    }

    if (event.code === 'Tab') {
        shouldAutosubmit = false
    }

    if (event.target.closest('[data-autosubmit="false"]') || event.target.closest('.Select')) {
        shouldAutosubmit = false
    }

    if (shouldAutosubmit) {
        clearTimeout(timer)
        let delay = parseInt(form.dataset.autosubmitDelay)
        if (isNaN(delay)) {
            delay = 500
        }
        timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), delay)
    }
})