export function createLoading() {
    const loading = document.createElement('div')
    loading.className = 'Loading'

    loading.innerHTML = `
        <div class="Spinner">
            <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="15" />
            </svg>
        </div>
    `

    return loading
}