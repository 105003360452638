$.nette.ext('loadingStates', {
    start(xhr, settings) {
        if (settings?.nette?.el) {
            settings.nette.el[0].classList.add('is-loading')
        }
    },
    complete(xhr, status, settings) {
        if (settings?.nette?.el) {
            settings.nette.el[0].classList.remove('is-loading')
        }
    }
})