export const EVENTS = {
    DOM_CHANGE: 'dom:change',

    MODAL_OPEN: 'modal:open', //natvrdo v Modal.js
    MODAL_CLOSE: 'modal:close', //natvrdo v Modal.js
    MODALOPENER_CLICK: 'modalopener:click', //natvrdo v Modal.js

    SEARCH_OPENED: 'search:opened',
    SEARCH_CLOSED: 'search:closed',
    SEARCH_TOGGLE: 'search:toggle',

    POPUPNAV_OPENED: 'popupnav:opened',
    POPUPNAV_CLOSED: 'popupnav:closed',
    POPUPNAV_TOGGLE: 'popupnav:toggle',

    SWUP_WILL_REPLACE_CONTENT: 'swup:willreplacecontent',
    SWUP_CLICK_LINK: 'swup:clicklink',
    SWUP_CONTENT_REPLACED: 'swup:contentreplaced',
    SWUP_POPSTATE: 'swup:popstate',

    SECTIONOBSERVER_SECTIONCHANGE: 'sectionobserver:sectionchange',
}