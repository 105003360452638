import Component from './Component'
import { queryAll } from '../utils/dom'
import { componentKey } from './config'
import uniq from 'lodash/uniq'

const components = {}
window.loadComponents = loadComponents
export async function loadComponents(context = document.documentElement) {
    const elements = queryAll('[data-component]', context)
        .filter(element => {
            const instance = Component.getFromElement(element)

            if (instance) {
                // console.warn('Error: instance exists: \n', instance)
                return false // continue
            }

            return true
        })

    const componentNames = uniq(elements
        .map(element => element.dataset.component)
        .filter(id => !(id in components)))

    const promises = componentNames
        .map(name => new Promise((resolve, reject) => {
            import(
                /* webpackChunkName: "[request]" */
                `../components/${name}`)
                .then(module => resolve({
                    name: name,
                    module: module.default
                }))
                .catch(error => resolve({
                    name: name,
                    module: null
                }))
        }))

    const defs = await Promise.all(promises)
    defs.forEach(def => components[def.name] = def.module)

    let componentList = [];

    elements.forEach(element => {
        const componentName = element.dataset.component
        if (typeof components[componentName] === 'function') {
            componentList.push(createInstance(element, componentName))
        } else {
            console.warn(`Constructor for component "${componentName}" not found.`)
        }
    })

    componentList.forEach(component => component.prepare())
    componentList = [];
}

export function removeComponents(context = document.documentElement) {
    queryAll('[data-component]', context).forEach(destroyInstance)
}

export const createInstance = (element, componentName) => {
    components[componentName].prototype._name = componentName
    const component = new components[componentName](element)

    console.info(`Created instance of component "${componentName}".`)
    return component
}

export const destroyInstance = element => {
    const instance = Component.getFromElement(element)
    if (instance) {
        const name = instance._name
        instance.destroy()
        element[componentKey] = null
        console.info(`Removed component "${name}".`)
    }
}