// set basepath of project for webpack
import { basePath } from "./core/config"
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import "console-polyfill"
import elementClosestPolyfill from "element-closest"
elementClosestPolyfill(window)
import "custom-event-polyfill"

import "./core/Sentry"

import $ from "jquery"

// jquery utils
import "jquery.easing"

import "./plugins/nette.ajax"
import "./plugins/nette.ajax.history"

// nette
import "./nette/NetteAutosubmit"
import "./nette/NetteValidationMessages"
import "./nette/NetteSnippets"
import "./nette/NetteEvents"
import "./nette/NetteLoading"

import Nette from "./nette/NetteForms"
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

try {
    Object.assign
    Array.filter
    HTMLElement.dataset
    HTMLElement.closest()
} catch (e) { }

import TouchMouseInputResolver from "./meta/TouchMouseInputResolver"
new TouchMouseInputResolver()

import { CMS } from "./core/config"
import ScrollService from "./services/Scroll"
ScrollService.useNative()
setScrollPosition()

import { loadComponents, removeComponents } from "./core/componentUtils"
window.frontend = {
    loadComponents,
    removeComponents,
    loadPromise: loadComponents(),
}

/**
 * lazy loading
 */
import lazySizes from "lazysizes"
lazySizes.cfg.lazyClass = "is-lazy"
lazySizes.cfg.loadingClass = "is-lazy-loading"
lazySizes.cfg.loadedClass = "is-lazy-loaded"
document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg")
    if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")"
    }
})

import Swup from "swup"
import GtmPlugin from "./plugins/swup/GtmPlugin" //znasilneni virtualPageUrl, ktery vraci v sobe breadcrumbs
import { getCurrentGtmPageEventObject } from './plugins/swup/getCurrentGtmPageEventObject'
import SwupBodyClassPlugin from "./plugins/swup/BodyClassPlugin" //fix pro necachovane stranky
import { queryAll } from "./utils/dom"
import EventBus from "./core/EventBus"
import { EVENTS } from "./core/Events"
import scrollHistory from './services/ScrollHistory'

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}

let isTabSwup = false
const lastScrollPosition = {
    x: 0,
    y: 0,
}


if (!CMS && !document.body.classList.contains('u-swup-disabled')) {
    if (typeof window.dataLayer === 'object') {
        const object = getCurrentGtmPageEventObject()
        window.dataLayer.push(object);
    }

    const swup = new Swup({
        linkSelector: [
            'a[href^="' +
            window.location.origin +
            '"]:not([data-no-swup]):not([href^="/cervenak"]):not([href^="https://pardubice.eu/cervenak"]):not([target="_blank"]):not(.ajax):not([download])',
            'a[href^="/"]:not([href^="/cervenak"]):not([href^="https://pardubice.eu/cervenak"]):not([data-no-swup]):not([target="_blank"]):not(.ajax):not([download])',
            // 'a[href^="#"]:not([data-no-swup]):not(.ajax)'
        ].join(", "),
        plugins: [
            new GtmPlugin(),
            new SwupBodyClassPlugin(),
        ],
        cache: false,
        animationSelector: ".Layout",
        containers: [
            "#swup",
            "#modals",
            "#header-navmainlinks",
            "#header-logo",
            "#footer-homepages",
            "#popupnav-page1main",
            "#trackers"
        ],
        skipPopStateHandling: (event) => {
            if (event?.state?.source === "swup") {
                return false
            }
            return true
        },
    })

    swup.on("samePage", (event) => {
        if (event.delegateTarget.dataset.forceSamePage !== undefined) {
            ScrollService.scrollToElement(document.querySelector(".Header"))
        }
    })

    swup.on("clickLink", (event) => {
        scrollHistory.saveCurrent()

        isTabSwup = event.delegateTarget.dataset.swupTransition === 'swup-tab' ? true : false

        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on("willReplaceContent", (event) => {
        if (isTabSwup) {
            lastScrollPosition.x = ScrollService.getScroll().x
            lastScrollPosition.y = ScrollService.getScroll().y
        } else {
            lastScrollPosition.x = 0
            lastScrollPosition.y = 0
        }

        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll("[data-swup]").forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on("contentReplaced", (event) => {
        Nette.reinit()

        queryAll("[data-swup]").forEach((element) => {
            loadComponents(element)
        })

        if ($.nette) {
            $.nette.load()
        }

        document.body.style.height = ``
        document.documentElement.classList.remove('is-popstate')

        requestAnimationFrame(() => {
            ScrollService.resize()

            const historyState = scrollHistory.getCurrent()
            if (historyState) {
                lastScrollPosition.x = historyState.scroll.x
                lastScrollPosition.y = historyState.scroll.y
            }
            setScrollPosition(lastScrollPosition.x, lastScrollPosition.y)
            EventBus.emit(EVENTS.SWUP_CONTENT_REPLACED)
        })
    })

    swup.on('popState', function () {
        EventBus.emit(EVENTS.SWUP_POPSTATE)

        const historyState = scrollHistory.getCurrent()
        if (historyState) {
            document.body.style.height = `${historyState.height}px`
            document.documentElement.classList.add('is-popstate')
        }
    })
}